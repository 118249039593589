import React, { useState } from "react";
import UserNavbar from "../Component/UserNavbar";
import { Accordion, Button, Form } from "react-bootstrap";
import VideoPlayer from "../../../components/common/VideoPlayer";

import { Icon } from "@iconify/react/dist/iconify.js";
import images from "../../../utils/images";
import UserSearchBar from "./UserSearchBar";
import {
  TestWithDetailedAnswerCard,
  TestWithVideoCard,
} from "../../../components/common/Cards";
import UserWishlistBar from "./UserWishlistBar";
import { useLocation, useNavigate } from "react-router-dom";

import DetailSolutionNavBar from "./DetailSolutionNavBar";

const DetailSolutionDiv = () => {
  const Items = [
    "OverView",
    "Teacher Notes",
    "Q & A",
    "Hand written notes by topper",
  ];
  const testDetailedAnswers = [
    {
      questionNo: 1,
      question:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
      timeSpent: "00.20",
      options: [
        {
          option: "A",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
          correct: false,
          yourAnswer: false,
        },
        {
          option: "B",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
          correct: false,
          yourAnswer: false,
        },
        {
          option: "C",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
          correct: false,
          yourAnswer: false,
        },
        {
          option: "D",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
          correct: true,
          yourAnswer: true,
        },
      ],
      AnswerExplaniation:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
    {
      questionNo: 2,
      question:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
      timeSpent: "00.20",
      options: [
        {
          option: "A",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
          correct: false,
          yourAnswer: true,
        },
        {
          option: "B",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
          correct: false,
          yourAnswer: false,
        },
        {
          option: "C",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
          correct: false,
          yourAnswer: false,
        },
        {
          option: "D",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
          correct: true,
          yourAnswer: false,
        },
      ],
      AnswerExplaniation:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    },
    {
      questionNo: 3,
      question:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
      timeSpent: "00.20",
      options: [
        {
          option: "A",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
          correct: false,
          yourAnswer: false,
        },
        {
          option: "B",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
          correct: false,
          yourAnswer: false,
        },
        {
          option: "C",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
          correct: false,
          yourAnswer: false,
        },
        {
          option: "D",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
          correct: true,
          yourAnswer: true,
        },
      ],
      AnswerExplaniation:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
    },
  ];
  const [selectedItem, setSelectedItem] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();

  const { dataItem } = location.state || {};
  if (!dataItem) return <div>No data available</div>;
  const testDetailedAnswers1 =
    dataItem?.currentUserDetails?.testPerformance?.questionTimes?.map(
      (section, index) => ({
        questionNo: index + 1,
        question: section.questionText?.slice(0,-2) || "Question",
        timeSpent: section.timeTaken || "00:00",
        options:
          section.options?.map((option, idx) => ({
            option: option?.label || "",
            
            value: option.option || "",
            correct: section.correctAnswer || false,
            yourAnswer: section.answer || false,
            optionImage: option?.optionImage || "",
          })) || [],
        AnswerExplaniation: section.correctAnswer || "",
        VideoLink: section.videoSolution || "",
      })
    );

  return (
    <div>
      <div>
        <DetailSolutionNavBar />
      </div>
      {console.log(dataItem)}
      <div className="myWishlistContainer">
        <div className="CoursePage5MainContainer">
          <div>
            <div className="UserSearchBarDivContainer1 pt-4">
              <div className="UserWishlistBarDivContainer2">
                <div className="d-flex flex-column g-2 p-2 pt-4">
                  {testDetailedAnswers1?.map((i, index) => {
                    return (
                      <TestWithDetailedAnswerCard
                        key={index}
                        test={i}
                        index={index}
                      />
                    );
                  })}
                </div>

                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailSolutionDiv;
