import { useContext, useEffect, useState } from "react";
import "./TestWithVideo.css";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { endpoints } from "../../../../services/endPoints";
import { getRequest, postRequest } from "../../../../services/apiService";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { UserContext } from "../../../../Context/Context";
import images from "../../../../utils/images";
import { StartReverseTimer } from "../../../../utils/timerReverse";
import { showNotification } from "../../../../components/common/APIComponents";
import { directSubmitTest } from "../../../../utils/otherApi";

const UserMainTest5 = () => {
  const [selectedSection, setSelectedSection] = useState(1);
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [instructionFileData, setInstructionFileData] = useState({});

  const [goalId, setGoalId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [allSections, setAllSections] = useState([]);
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [selectedQuestionId, setSelectedQuestionId] = useState("");
  const [questionsForReview, setQuestionsForReview] = useState([]);
  const [questionsNotVisited, setQuestionsNotVisited] = useState([]);
  const [questionsAttampted, setQuestionsAttampted] = useState([]);
  const [questionsNotAnswered, setQuestionsNotAnswered] = useState([]);
  const [questionsMarkedAndAnswers, setQuestionsMarkedAndAnswers] = useState(
    []
  );
  const [submitLoading, setSubmitLoading] = useState(false);
  const [marksType, setMarksType] = useState({});

  const [questionTimerInterval, setQuestionTimerInterval] = useState(null);
  const [currentQuestionTime, setCurrentQuestionTime] = useState("00:00");
  const [totalTime, setTotalTime] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [allQuestions, setAllQuestions] = useState([]);
  const [optionSelected, setOptionSelected] = useState(
    allQuestions?.find((question) => question._id === selectedQuestionId)
      ?.selectedOption || null
  );
  const [testResponse, setTestResponse] = useState({});
  const userData = useContext(UserContext);
  const { id } = useParams();
  const location = useLocation();
  const {
    todo,
    week,
    itemName,
    itemId,
    isPurchased,
    isInnerTest,
    isInnerTestName,
    testSeriesIdMain,
  } = location.state;
  console.log(location.state);
  const testId = todo?._id;
  const testSeriesFileId = testSeriesIdMain;

  const getUserData = () => {
    const parmas = {
      testId,
      testSeriesFileId,
    };
    getRequest({
      endpoint: endpoints.getTestInstructions,
      setIsLoading,
      params: parmas,
    }).then((res) => {
      if (isInnerTestName === "practice") {
        const { instructionFile, practiceFile } =
          todo?.practiceFiles?.[0] || {};
        setMarksType(instructionFile?.subjectWiseMarks?.[0] || {});
        const testId = practiceFile?._id;
        const sections = practiceFile?.sections || [];
        const allQuestions = sections.flatMap((section) =>
          section.questions.map((question) => ({
            ...question,
            startTime: "00:00",
            marked: false,
            reviewed: false,
            markedAndReviewed: false,
            notVisited: true,
            notAttempted: true,
            pauseTime: "00:00",
            selectedOption: null,
          }))
        );
        setData(instructionFile);
        setTotalTime(instructionFile?.totalTime || 0);
        setTimeLeft(instructionFile?.totalTime || 0);
        setAllSections(sections);
        setAllQuestions(allQuestions);

        if (sections.length > 0 && sections[0].questions.length > 0) {
          setSelectedSectionId(sections[0]._id);
          setSelectedQuestionId(sections[0].questions[0]._id);
        }
      } else {
        const { instructionFile, testSeriesFile } =
          todo?.testSeriesFiles?.[0] || {};
        setMarksType(instructionFile?.subjectWiseMarks?.[0] || {});
        const testId = testSeriesFile?._id;
        const sections = testSeriesFile?.sections || [];
        const allQuestions = sections.flatMap((section) =>
          section.questions.map((question) => ({
            ...question,
            startTime: "00:00",
            marked: false,
            reviewed: false,
            markedAndReviewed: false,
            notVisited: true,
            notAttempted: true,
            pauseTime: "00:00",
            selectedOption: null,
          }))
        );
        setData(instructionFile);
        setTotalTime(instructionFile?.totalTime || 0);
        setTimeLeft(instructionFile?.totalTime || 0);
        setAllSections(sections);
        setAllQuestions(allQuestions);

        if (sections.length > 0 && sections[0].questions.length > 0) {
          setSelectedSectionId(sections[0]._id);
          setSelectedQuestionId(sections[0].questions[0]._id);
        }
      }
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  const startQuestionTimer = (questionId) => {
    if (questionTimerInterval) {
      clearInterval(questionTimerInterval);
    }

    setQuestionTimerInterval(
      setInterval(() => {
        setAllQuestions((prevQuestions) =>
          prevQuestions.map((question) => {
            if (question._id === questionId) {
              return {
                ...question,
                elapsedSeconds: (question.elapsedSeconds || 0) + 1,
              };
            }
            return question;
          })
        );
      }, 1000)
    );
  };

  const stopQuestionTimer = () => {
    if (questionTimerInterval) {
      clearInterval(questionTimerInterval);
      setQuestionTimerInterval(null);
    }
    setAllQuestions((prevQuestions) =>
      prevQuestions.map((question) => ({
        ...question,
        pauseTime: question.elapsedSeconds || "00:00",
      }))
    );
  };

  const handleQuestionTimeChange = (questionId) => {
    const updatedQuestions = allQuestions.map((question) => {
      if (question._id === questionId) {
        return {
          ...question,
          elapsedSeconds: question.elapsedSeconds || 0,
        };
      }
      return question;
    });
    setAllQuestions(updatedQuestions);
  };

  useEffect(() => {
    handleQuestionTimeChange(selectedQuestionId);

    if (selectedQuestionId) {
      startQuestionTimer(selectedQuestionId);
    }

    return () => {
      stopQuestionTimer();
    };
  }, [selectedQuestionId]);

  useEffect(() => {
    const currentQuestion = allQuestions.find(
      (question) => question._id === selectedQuestionId
    );

    if (currentQuestion) {
      const elapsedSeconds = currentQuestion.elapsedSeconds || 0;
      const minutes = String(Math.floor(elapsedSeconds / 60)).padStart(2, "0");
      const seconds = String(elapsedSeconds % 60).padStart(2, "0");

      setCurrentQuestionTime(`${minutes}:${seconds}`);
    }
  }, [allQuestions, selectedQuestionId]);
  useEffect(() => {
    const currentQuestion = allQuestions.find(
      (question) => question._id === selectedQuestionId
    );
    setOptionSelected(currentQuestion?.selectedOption || null);
  }, [selectedQuestionId, allQuestions]);
  const renderOptions = () => {
    const selectedQuestion = allSections[selectedSection - 1]?.questions?.find(
      (question) => question._id === selectedQuestionId
    );

    if (!selectedQuestion?.options) return null;

    return selectedQuestion.options.map((option, index) => (
      <p key={index} className="d-flex align-items-center">
        <span>
          <Form.Check
            type="radio"
            label={option?.option}
            name="optionBtn"
            value={option?.label}
            onChange={handleChangeOptions}
            checked={optionSelected === option?.label}
          />
        </span>
        {option?.optionImage && (
          <span>
            <img src={option?.optionImage} alt={`Option Image ${index}`} />
          </span>
        )}
      </p>
    ));
  };
  function MyVerticallyCenteredModal(props) {
    const [status, setStatus] = useState("first");
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          {status === "first" ? (
            <div className="modal-first">
              <div className="modal-first__title">
                <img src={images.UserTestSubmitted} alt="testCompleted" />
              </div>
              <div className="modal-first__subtitle">
                Test successfully Submitted
              </div>
              <div className="modal-first__btns">
                <Button
                  className="modal-first__btn"
                  onClick={() => setStatus("second")}
                >
                  View Results
                </Button>
              </div>
            </div>
          ) : status === "second" ? (
            <div className="modal-second">
              <div className="modal-second__title">Test Summary</div>
              <div className="modal-second__subtitle">
                Your answer have been saved successfully please take few moments
                to review this summary.
              </div>
              <div className="modal-second__content">
                <p className="modal-second__content__item">
                  <span>No. of questions </span>
                  <span>{allQuestions?.length}</span>
                </p>
                <p className="modal-second__content__item">
                  <span>Answered </span>
                  <span>
                    {" "}
                    {(allQuestions?.length || 0) -
                      (testResponse?.unansweredQuestions?.length || 0)}
                  </span>
                </p>
                <p className="modal-second__content__item">
                  <span>Not Answered</span>
                  <span>{testResponse?.unansweredQuestions?.length || 0}</span>
                </p>
                <p className="modal-second__content__item">
                  <span>Marked for review </span>
                  <span>{questionsForReview?.length || 0}</span>
                </p>
                <p className="modal-second__content__item">
                  <span>Marked & Answered </span>
                  <span>{questionsMarkedAndAnswers?.length || 0}</span>
                </p>
                <p className="modal-second__content__item">
                  <span>Not Visited</span>
                  <span>{questionsNotVisited?.length || 0}</span>
                </p>
              </div>

              <div className="modal-second__btns">
                <Button
                  className="modal-second__btn"
                  onClick={
                    () =>
                      navigate(`/test/analysis/${testId}/${ itemName==="PYQ" ? itemId :testSeriesIdMain}`)
                  }
                >
                  Proceed
                </Button>
              </div>
            </div>
          ) : status === "third" ? (
            <div className="modal-third">
              <div className="modal-third__title">Take Another Test</div>
              <div className="modal-third__subtitle">
                You can take another test if you want
              </div>

              <div className="modal-third__btns">
                <Button
                  className="modal-third__btn"
                  onClick={() => setStatus("first")}
                >
                  Back
                </Button>
                <Button
                  className="modal-third__btn"
                  onClick={() => setStatus("fourth")}
                >
                  Take Another Test
                </Button>
              </div>
            </div>
          ) : (
            <div className="modal-fourth">
              <div className="modal-fourth__title">Take Another Test</div>
              <div className="modal-fourth__subtitle">
                You can take another test if you want
              </div>
              <div className="modal-fourth__btns">
                <Button
                  className="modal-fourth__btn"
                  onClick={() => setStatus("first")}
                >
                  Back
                </Button>
                <Button
                  className="modal-fourth__btn"
                  onClick={() => setStatus("fifth")}
                >
                  Take Another Test
                </Button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }
  const handleChangeOptions = (e) => {
    const { value } = e.target;
    setOptionSelected(value);

    setAllQuestions((prevQuestions) =>
      prevQuestions.map((question) => {
        if (question._id === selectedQuestionId) {
          return {
            ...question,
            selectedOption: value,
          };
        }
        return question;
      })
    );
  };

  useEffect(() => {
    const currentQuestion = allQuestions.find(
      (question) => question._id === selectedQuestionId
    );
    setOptionSelected(currentQuestion?.selectedOption || null);
  }, [selectedQuestionId, allQuestions]);

  const handleSaveAndNext = () => {
    setAllQuestions((prevQuestions) =>
      prevQuestions.map((question) => {
        if (question._id === selectedQuestionId) {
          return {
            ...question,
            selectedOption: optionSelected,
            notAttempted: false,
          };
        }
        return question;
      })
    );

    const currentSection = allSections[selectedSection - 1];
    const questions = currentSection?.questions || [];

    const currentIndex = questions.findIndex(
      (question) => question._id === selectedQuestionId
    );

    if (currentIndex !== -1 && currentIndex < questions.length - 1) {
      setSelectedQuestionId(questions[currentIndex + 1]._id);
    } else if (selectedSection < allSections.length) {
      const nextSectionIndex = selectedSection;
      const nextSection = allSections[nextSectionIndex];
      const nextSectionFirstQuestion = nextSection?.questions[0];

      if (nextSectionFirstQuestion) {
        setSelectedSection(selectedSection + 1);
        setSelectedQuestionId(nextSectionFirstQuestion._id);
      } else {
      }
    } else {
      handleTestSubmit();
    }
  };

  const handleTestSubmit = () => {
    stopQuestionTimer();
    setSubmitLoading(true);

    const payload = {
      testId: testId || todo?._id || null,
      // testSeriesId: testSeriesIdMain || null,
      answers: [],
      timesTaken: [],
    };
    if(isInnerTestName === "practice"){
  
    payload.practiceQuestionId = testSeriesIdMain
    }
    
    else{
      payload.testSeriesId = testSeriesIdMain
    }


    allQuestions.forEach((question) => {
      const { selectedOption, elapsedSeconds } = question;

      const timeInMinutes =
        Math.round(((elapsedSeconds || 0) / 60) * 100) / 100;

      payload.answers.push(selectedOption || null);
      payload.timesTaken.push(timeInMinutes);
    });
    if (!isPurchased) {
      showNotification({
        type: "error",
        message: "You are not authorized to access this page",
      });
      return navigate("/user/home");
    }
    if (itemName === "StudyPlanner") {
      payload.studyPlannerId = itemId;
      payload.testSeriesId = testSeriesIdMain;
      postRequest({
        endpoint: endpoints.directSubmitTest,
        data: payload,
        setIsLoading,
      }).then((res) => {
        setTestResponse(res?.data);
        if (res?.data) setModalShow(true);
        setSubmitLoading(false);
      });
    } else if (itemName === "Course") {
      payload.courseId = itemId;
      payload.testSeriesId = testSeriesIdMain;
      payload.testId = todo?._id;
      postRequest({
        endpoint: endpoints.directSubmitTest,
        data: payload,
        setIsLoading,
      }).then((res) => {
        setTestResponse(res?.data);
        if (res?.data) setModalShow(true);
        setSubmitLoading(false);
      });
    } else if (itemName === "PYQ") {
      // payload.capsuleCourseId = itemId;
      // payload.testSeriesId = testSeriesIdMain;
      // payload.testId = todo?._id;
      payload.previousYearQuestionId = itemId;
      payload.testSeriesId = itemId;
      postRequest({
        endpoint: endpoints.directSubmitTest,
        data: payload,
        setIsLoading,
      }).then((res) => {
        setTestResponse(res?.data);
        if (res?.data) setModalShow(true);
        setSubmitLoading(false);
      });
    } 
     else if (itemName === "CapsuleCourse") {
      payload.capsuleCourseId = itemId;
      payload.testSeriesId = testSeriesIdMain;
      payload.testId = todo?._id;
      postRequest({
        endpoint: endpoints.directSubmitTest,
        data: payload,
        setIsLoading,
      }).then((res) => {
        setTestResponse(res?.data);
        if (res?.data) setModalShow(true);
        setSubmitLoading(false);
      });
    } 
    
    
    else {
      postRequest({
        endpoint: endpoints.postSubmitTest,
        data: payload,
        setIsLoading,
      }).then((res) => {
        setTestResponse(res?.data);
        if (res?.data) setModalShow(true);
        setSubmitLoading(false);
      });
    }
  };

  return (
    <div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div className="TestWithVideoPage5Container">
        <div className="TestWithVideoPage5">
          <div className="TestWithVideoPage5Header">
            <span>Cancel</span>
            <span>
              Time Left :{" "}
              <span>
                <StartReverseTimer timeLeft={timeLeft} />
              </span>
            </span>
          </div>
          <div className="TestWithVideoPage5Content">
            <div className="TestWithVideoPage5Content1">
              <div className="TestWithVideoPage5Content1P">
                {allSections &&
                  allSections.map((section, index) => (
                    <p
                      key={index}
                      className={
                        selectedSection === index + 1
                          ? "TestWithVideoPage5Content1P1Active"
                          : "TestWithVideoPage5Content1P1"
                      }
                      onClick={() => {
                        setSelectedSection(index + 1);
                        setSelectedQuestionId(section?.questions[0]?._id);
                      }}
                    >
                      Section {index + 1}
                    </p>
                  ))}
              </div>
              <div className="TestWithVideoPage5Content1Q">
                <p className="TestWithVideoPage5Content1Q1">
                  <span>
                    <span style={{ color: "#a3a3a3" }}>Section Name </span>
                    <span style={{ color: "#000" }}>
                      {allSections[selectedSection - 1]?.title}
                    </span>
                  </span>
                  <span className="TestWithVideoPage5Content1Q1Time">
                    <span>
                      Time: <span>{currentQuestionTime}</span>
                    </span>
                    <span>
                      Marks:{" "}
                      <span>
                        +{marksType?.positiveMarks || 0} -
                        {marksType?.negativeMarks || 0}
                      </span>
                    </span>
                  </span>
                </p>
                <hr />
                <p className="TestWithVideoPage5Content1Q2">
                  <span className="d-flex flex-column g-2">
                    <span>
                      {allSections[selectedSection - 1]?.questions
                        ?.find(
                          (question) => question._id === selectedQuestionId
                        )
                        ?.text?.slice(0, -3)}
                    </span>
                    <span>
                      {allSections[selectedSection - 1]?.questions
                        ?.find(
                          (question) => question._id === selectedQuestionId
                        )
                        ?.questionImages?.map((image, index) => (
                          <img
                            key={index}
                            src={image}
                            alt={`Question Image ${index}`}
                          />
                        ))}
                    </span>
                  </span>
                </p>
                <hr />
                <p className="TestWithVideoPage5Content1Q3">
                  <span>Is it correct to say any question?</span>
                  <span>
                    <span>{renderOptions()}</span>
                  </span>
                </p>
                <p className="TestWithVideoPage5Content1Q4">
                  <span
                    onClick={() => {
                      questionsForReview.push(selectedQuestionId);

                      const currentSection = allSections[selectedSection - 1];
                      const questions = currentSection?.questions || [];

                      const currentIndex = questions.findIndex(
                        (question) => question._id === selectedQuestionId
                      );

                      if (
                        currentIndex !== -1 &&
                        currentIndex < questions.length - 1
                      ) {
                        setSelectedQuestionId(questions[currentIndex + 1]._id);
                      } else {
                      }
                    }}
                  >
                    Mark for review & Next
                  </span>
                  <span
                    className="TestWithVideoPage5Content1Q4Span"
                    onClick={() => handleSaveAndNext()}
                  >
                    Save & Next
                  </span>
                </p>
              </div>
            </div>
            <div className="TestWithVideoPage5Content21">
              <p>
                <p className="TestWithVideoPage5Content21P">
                  <span>
                    <img
                      src={
                        userData?.user?.image || images.userAllDashboardImage
                      }
                      alt="userImage"
                    />
                  </span>
                  <span>{userData?.user?.fullName}</span>
                </p>
                <p className="TestWithVideoPage5Content21P2">
                  <p>
                    <span>{questionsAttampted?.length || 0}</span>
                    <span>Attempted </span>
                  </p>
                  <p>
                    <span>{questionsForReview?.length || 0}</span>
                    <span>Marked </span>
                  </p>
                  <p>
                    <span>{questionsNotVisited?.length || 0}</span>
                    <span>Not Visited </span>
                  </p>
                  <p>
                    <span>{questionsNotAnswered?.length || 0}</span>
                    <span>Not Answered </span>
                  </p>
                  <p>
                    <span>{questionsMarkedAndAnswers?.length || 0}</span>
                    <span>Marked & Answered </span>
                  </p>
                </p>
                <h4></h4>
                <p className="TestWithVideoPage5Content21P3">
                  {allQuestions?.map((question, index) => (
                    <span
                      onClick={() => {
                        setSelectedQuestionId(question._id);
                      }}
                      key={index}
                    >
                      {index + 1}
                    </span>
                  ))}
                </p>
              </p>
              <p className="mt-26 d-flex justify-content-center">
                <Button
                  disabled={submitLoading}
                  onClick={() => handleTestSubmit()}
                >
                  {submitLoading ? <Spinner animation="border" /> : "Submit"}
                </Button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMainTest5;
