import { useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import { UserBannerTopComponent } from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate } from "react-router-dom";

import { Spinner } from "react-bootstrap";
import { getRequest } from "../../../services/apiService";
import { endpoints } from "../../../services/endPoints";
const StudyPlannerBanner = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [goalId, setGoalId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getUserProfile,
      setIsLoading,
    }).then((res) => {
      setGoalId(res?.data?.user?.goal?._id);
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (goalId) {
      getRequest({
        endpoint: endpoints.getAboutExamGoalById(goalId),
        setIsLoading,
      }).then((res) => {
        if (res.data && res.data.length > 0) {
          setData(res.data[res.data.length - 1]);
        } else {
        }
      });
    }
  }, [goalId]);

  const [planSelected, setPlanSelected] = useState("lite");
  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar />
        </div>
        <div className="user_container_width">
          {/* <img
            style={{
              width: "100%",
              height: "100%",
              cursor: "pointer",
              borderRadius: "10px",
            }}
            onClick={() => navigate("/user/study-planner-ai")}
            src={images.userStudyModal}
            alt=""
          /> */}

          <div className="StudyPlannerBannerPageContainer">
            <div className="plan-comparison">
              <div className="comparison-grid">
                <div className="features">
                  {[
                    "Choose Your Plan and Start Learning",
                    "Time Management",
                    "Exam Readiness Analysis",
                    "Personalized Feedbacks",
                    "Exam Eligibility Check",
                    "Topic-Wise Mastery",
                    "Goal-Oriented Counseling",
                    "Flexible Pacing",
                  ].map((feature, index) => (
                    <p key={index} className="feature-item">
                      {feature}
                    </p>
                  ))}
                </div>

                <div className="column-lite">
                  <div
                    onClick={() => setPlanSelected("lite")}
                    className={
                      planSelected === "lite"
                        ? "plan-title lite selected"
                        : "plan-title lite"
                    }
                  >
                    Personalized Lite
                  </div>
                  {["✔", "✔", "✔", "✖", "✖", "✖", "✖"].map((status, index) => (
                    <p
                      key={index}
                      className={
                        planSelected === "lite" ? "status lite" : "status"
                      }
                    >
                      {status}
                    </p>
                  ))}
                </div>

                <div className="column-pro">
                  <div
                    onClick={() => setPlanSelected("pro")}
                    className={
                      planSelected === "pro"
                        ? "plan-title pro selected"
                        : "plan-title pro"
                    }
                  >
                    Personalized Pro
                  </div>
                  {["✔", "✔", "✔", "✔", "✔", "✔", "✔"].map((status, index) => (
                    <p
                      key={index}
                      className={
                        planSelected === "pro" ? "status pro" : "status"
                      }
                    >
                      {status}
                    </p>
                  ))}
                  <div hidden={planSelected === "lite"} className="coming-soon">
                    <img src={images.userCommingSoon} alt="image" />
                  </div>
                </div>
              </div>
              <div className="cta-button-container">
                <button
                  disabled={planSelected === "pro"}
                  onClick={() => navigate("/user/study-planner-ai")}
                  className={
                    planSelected === "pro"
                      ? "cta-button disabled"
                      : "cta-button"
                  }
                >
                  CONTINUE WITH YOUR STUDY PLAN
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(StudyPlannerBanner);
