export const endpoints = {
  // Authentication endpoints
  login: "/auth/login",
  register: "/auth/register",
  logout: "/auth/logout",

  // User endpoints
  getUserProfile: "/user/profile",
  updateUserProfile: "/user/edit/profile",
  updateUserProfilePic: "/user/upload-profile-picture",
  changePassword: "/user/change-password",
  getHomePageBanners: "/admin/Banner/bannerByPosition",
  getMyCourses: "/user/my-courses/byfilter",
  getCoupons: "/user/coupons",
  getTopContent: "/user/top-content",
  getTopContentById: (id) => `/user/top-content/${id}`,
  getGoalsData: "user/goal-category",
  getGoalById: (goalId) => `/user/goals/byGoalCaegory/${goalId}`,
  getGoalsDataById: `/user/study-plans`,
  getCapsuleCourseById: `/user/capsuleCourse/byChapter/byCourse/byTopic`,
  getcreateStudyPlanner: "/user/get/user-study-planner",
  getStudyPlannerById: (studyPlannerId) =>
    `/user/user-study-planner/${studyPlannerId}`,
  getAboutUs: "/static/getAboutUs",
  getContactUs: "/admin/call-us",
  getAboutExamGoalById: (goalId) => `/user/aboutExam/byGoal/${goalId}`,
  getStudyPlannerGoalById: (goalId) => `/user/studyPlanner/byGoal/${goalId}`,
  getCourses: "/user/Category/getAllCategory",
  getCourseById: (courseId) => `/user/courses/${courseId}`,
  getCourseTypeById: (courseId) => `/user/courses/${courseId}`,
  getCourseByIdFilter: "user/courses/byChapter/byCourse/byTopic",
  getCourseByIdFilterStudyPlanner: (courseId) =>
    `/user/study-planners/${courseId}`,
  getChapterBySubjectIdFilter: "user/chapters/bysubject/byCourse",
  getTestSeries: "/user/test-series",
  getTestSeriesById: (testSeriesId) => `/user/test-series/${testSeriesId}`,
  postSubmitTest: "/user/my-courses/submit-test",
  getQaData: (courseId) => `/user/topics/${courseId}/qanda`,
  getTestViewDetails: "user/my-courses/track/test",
  getHandWrittenNotes: "/user/handwritten-notes",
  getHandWrittenNotesById: (noteId) => `/user/handwritten-notes/${noteId}`,
  getPYQVideos: "/user/previous-year-questions",
  getPYQVideosByCourse: (courseId) =>
    `/user/previous-year-questions/${courseId}`,
  getPracticeQuestionById: (id) => `/user/practice-questions/${id}`,
  getPYQVideosById: (videoId) => `/user/previous-year-questions/${videoId}`,
  getCapsuleCourse: "/user/capsuleCourse",
  // getCapsuleCourseById: (capsuleId) => `/user/capsuleCourse/${capsuleId}`,
  getSkills: "/user/skills",
  getSkillsById: (skillId) => `/user/skills/${skillId}`,
  getCurrentAffairs: "/user/currentAffairs",
  getYoutubeVideos: "/user/youtubeVideos",
  getTargetCurrent: "/user/targetCurrentAffairs",
  getPurchaseCourses: "/user/my-courses",
  getCommunityPosts: "/user/posts",
  getTestInstructions: "/user/test-series-file",
  getTestInstructionsPYQ: "/user/previous-year-questions/test-series-file",
  getUserNotifications: "/user/notifications",
  getCapsuleCourseByIdFilter: "user/capsuleCourse/byChapter/byCourse/byTopic",
  getPostById: (postId) => `/posts/${postId}`,
  getQandAbyIdMainId: (mainId) => `/user/topics/${mainId}`,
  postQandA: (postId) => `/user/topics/${postId}/qanda`,
  createPost: "/user/posts",
  updatePost: (postId) => `/posts/${postId}/update`,
  deletePost: (postId) => `/posts/${postId}/delete`,
  likePost: (postId) => `/user/posts/${postId}/like`,
  commentPost: (postId) => `/user/posts/${postId}/comment`,
  pinPost: (postId) => `/user/posts/${postId}/save`,
  getFooterSectionData: "/static/getStaticContentByType",
  addToCart: "/user/cart/add",
  removeFromCart: "/user/cart/remove",
  getWishlistItems: "/user/courses/wishlist/get",
  getCartItems: "/user/cart",
  startTest: "/user/mycourse/add-test-performance",
  startTestTWV: "/user/my-courses/start",
  getTimeLineData: "/user/logs/getWeeklySummary",
  directStartTest: "/user/mycourse/add-test-performance",
  directSubmitTest: "/user/mycourse/submit-test-performance",
startMainTest:"/user/my-courses/start",
  addToWishlist: "/user/courses/wishlist/add",
  checkOutItems: "/user/Cart/checkout",
  placeOrderById: (orderId) => `/user/Cart/placeOrder/${orderId}`,

  // Comment endpoints
  getComments: (postId) => `/comments/${postId}`,
  createComment: "/comments/create",

  // Other endpoints
  getStatistics: "/statistics",
  getCategories: "/categories",
  getTestDashboard: "/user/my-courses/track/test/dashboard",
  deleteCart: "/user/cart/delete",
};
