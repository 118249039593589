import { useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import {
  BannerComponent,
  UserBannerTopComponent,
} from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate } from "react-router-dom";
import "./HandWrittenNotes.css";
import { HandWrittenNotesUserCardComponent } from "../../../components/common/Cards";
import { Button, Spinner } from "react-bootstrap";
import ProgressBar from "@ramonak/react-progress-bar";
import { getRequest } from "../../../services/apiService";
import { endpoints } from "../../../services/endPoints";
const HandWrittenNotes = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const handWrittenNotesUserCard = [
    {
      name: "Rajesh Kumar",
      iconImage: images.userHandWrittenNotesImage2,
      title: "GATE SCA Digital Logic ",
      pages: 20,
      subTopics: 14,
      rating: 5,
    },
    {
      name: "Kamlesh Kumar",
      iconImage: images.userHandWrittenNotesImage2,
      title: "GATE SCA Digital Logic ",
      pages: 20,
      subTopics: 14,
      rating: 5,
    },
    {
      name: "Rajesh Kumar",
      iconImage: images.userHandWrittenNotesImage2,
      title: "GATE SCA Digital Logic ",
      pages: 20,
      subTopics: 14,
      rating: 5,
    },
    {
      name: "Kamlesh Kumar",
      iconImage: images.userHandWrittenNotesImage2,
      title: "GATE SCA Digital Logic ",
      pages: 20,
      subTopics: 14,
      rating: 5,
    },
  ];
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getHandWrittenNotes,
      setIsLoading,
    }).then((res) => {
      setData(res?.data);
      setHasNextPage(res?.pagination?.hasNextPage);
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  const [bannerData, setBannerData] = useState({});
  const getBanner = () => {
    const params = {
      position: "TOP",
      type: "HandwrittenNotes",
    };
    getRequest({
      endpoint: endpoints.getHomePageBanners,
      setIsLoading,
      params,
    }).then((res) => {
      setBannerData(res?.data);
    });
  };
  useEffect(() => {
    getBanner();
  }, []);
  const [isLoading2, setIsLoading2] = useState(false);
  const [purchasedCourses, setPurchasedCourses] = useState([]);
  const getPurchaseCourses = () => {
    getRequest({
      endpoint: endpoints.getMyCourses,

      params: { type: "HandwrittenNotes", limit: 1000 },
      setIsLoading2,
    }).then((res) => {
      setPurchasedCourses(res?.data);
    });
  };

  useEffect(() => {
    getPurchaseCourses();
  }, []);
  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar
            DashboardName={"Dashboard"}
            link={"/user/handwritten-notes-by-toppers-analysis"}
          />
        </div>
        <div className="user_container_width">
          <div>
            <div>
              {bannerData && (
                <BannerComponent
                  image={
                    bannerData?.image?.[bannerData?.image?.length - 1] ||
                    images.userHandWrittenNotesImage
                  }
                  imagecss={"userHandWrittenNotesBannerImage"}
                  alt="alttext"
                />
              )}
            </div>
            <div className="mt-4 mb-2">
              {purchasedCourses.length > 0 && (
                <p className="dashboardPage_continueWhereYouLeft_0">
                  Your Purchased Handwrittens Notes
                </p>
              )}
              {purchasedCourses.length > 0 &&
                purchasedCourses?.map((i, index) => (
                  <div
                    onClick={() =>
                      navigate("/user-handwritten-notes-purchased")
                    }
                    className="dashboardPage_continueWhereYouLeft_1 user_container_width mb-2"
                  >
                    <div className="dashboardPage_continueWhereYouLeft_1_1">
                      <img src={images.userDashboardTopBanner} alt="image" />
                    </div>
                    <div className="dashboardPage_continueWhereYouLeft_1_2">
                      <span>GATE SCA Digital Logic</span>
                      <span>
                        <ProgressBar
                          completed={60}
                          labelColor="gray"
                          bgColor="linear-gradient(90deg, #A37BF5, #F3A952) "
                          isLabelVisible={false}
                        />
                      </span>
                    </div>
                    <div className={"dashboardPage_continueWhereYouLeft_1_3"}>
                      <Button>Start</Button>
                    </div>
                  </div>
                ))}
            </div>
            <div>
              <div className="userHandWrittenNotesCards gridLayout4">
                {data &&
                  data?.map((i, index) => (
                    <HandWrittenNotesUserCardComponent
                      name={i.name}
                      image={i.image}
                      title={i.title}
                      pages={i.pagesCount}
                      subtopics={i.topic?.length}
                      rating={i.rating || 0}
                      path={`/user/handwritten-notes/subjects/${i._id}`}
                    />
                  ))}
              </div>
              <div className="d-flex justify-content-center">
                {!isLoading && hasNextPage ? (
                  <Button
                    className="userDashboardPage_continer4_1_1"
                    onClick={() => setPage((prevPage) => prevPage + 1)}
                  >
                    Load More
                  </Button>
                ) : isLoading ? (
                  <div className="loader">
                    <Spinner animation="border" />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(HandWrittenNotes);
