import React, { useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import { UserBannerTopComponent } from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate } from "react-router-dom";
import "./TestWithVideo.css";
import {
  DashboardUserCourseCard,
  TestWithVideoCard,
} from "../../../components/common/Cards";
import { Button, Spinner } from "react-bootstrap";
import ProgressBar from "@ramonak/react-progress-bar";
import { getRequest } from "../../../services/apiService";
import { endpoints } from "../../../services/endPoints";
const TestWithVideo = () => {
  const navigate = useNavigate();
  const testwithVideoUserCard = [
    {
      name: "RRB NTPC (CBT 1 + CBT 2) 2024 Mock Test Series",
      iconImage: images.userTestWithVideoImage,
      description: "🌐 English, Hindi + 6 More",
      descriptionArray: ["1100 Total Tests", "10 Free tests"],
      courseText: "Start Foundation Course",
      pointsArray: [
        "96 Current General knowledge CT",
        "269 Chapter test",
        "+756 more test",
      ],
      users: 80,
    },
    {
      name: "RRB NTPC (CBT 1 + CBT 2) 2024 Mock Test Series",
      iconImage: images.userTestWithVideoImage,
      description: "🌐 English, Hindi + 6 More",
      descriptionArray: ["1100 Total Tests", "10 Free tests"],
      courseText: "Start Foundation Course",
      pointsArray: [
        "96 Current General knowledge CT",
        "269 Chapter test",
        "+756 more test",
      ],
      users: 80,
    },
  ];

  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [purchasedCourses, setPurchasedCourses] = useState([]);
  const getPurchaseCourses = () => {
    getRequest({
      endpoint: endpoints.getMyCourses,

      params: { type: "TestSeries", limit: 1000 },
      setIsLoading2,
    }).then((res) => {
      setPurchasedCourses(res?.data);
    });
  };

  useEffect(() => {
    getPurchaseCourses();
  }, []);

  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getTestSeries,
      setIsLoading,
    }).then((res) => {
      setData(res?.data);
      setTotalPages(res?.pagination?.totalPages);
      setHasNextPage(res?.pagination?.hasNextPage);
    });
  };

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar
            DashboardName={"Dashboard"}
            link={"/user/tests-analysis"}
          />
        </div>
        <div className="user_container_width">
          <div className="mt-2 mb-2">
            {purchasedCourses.length > 0 && (
              <p className="dashboardPage_continueWhereYouLeft_0">
                Your Purchased Test Series
              </p>
            )}
            {purchasedCourses?.length > 0 &&
              purchasedCourses?.map((i) => (
                <div
                  onClick={() => navigate(`/user/test/${i?.testSeries?._id}`)}
                  className="dashboardPage_continueWhereYouLeft_1 user_container_width mb-2"
                >
                  <div className="dashboardPage_continueWhereYouLeft_1_1">
                    <img src={images.userDashboardTopBanner} alt="image" />
                  </div>
                  <div className="dashboardPage_continueWhereYouLeft_1_2">
                    <span>{i?.testSeries?.bundleName}</span>
                    <span>
                      <ProgressBar
                        completed={i?.progress}
                        labelColor="gray"
                        bgColor="linear-gradient(90deg, #A37BF5, #F3A952) "
                        isLabelVisible={false}
                      />
                    </span>
                  </div>
                  <div className={"dashboardPage_continueWhereYouLeft_1_3"}>
                    <Button>Start</Button>
                  </div>
                </div>
              ))}
          </div>
          {/* <div className="user_testwithvideo_container">
            <div className="user_testwithvideo_container1">
              <p className="user_testwithvideo_container1_1">
                Your Previous Test Wasn’t Submitted{" "}
              </p>
              <p className="user_testwithvideo_container1_2">
                Starting the current test will automatically cancel the previous
                one.Click on the ‘start test’ button to attempt the current
                test:
              </p>
              <p className="user_testwithvideo_container1_3">
                <span>JEE Main Mock Test - 1</span>
                <span className="user_testwithvideo_container1_4">
                  <Button
                    onClick={() => navigate("/user/test-video-solution/1/1 ")}
                  >
                    Start Test
                  </Button>
                </span>
              </p>
            </div>
          </div> */}
          <div className="user_testwithvideo_container2 ">
            <div className="gridLayout3">
              {}
              {data &&
                data?.map((i, index) => {
                  return (
                    <TestWithVideoCard
                      key={index}
                      name={i.bundleName}
                      image={i.tileImage}
                      description={i.description || ""}
                      descriptionArray={i.descriptionArray || []}
                      courseText={i.courseText}
                      pointsArray={i.pointsArray || []}
                      users={i.users}
                      item={i}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="userDashboardPage_continer4_1">
        {!isLoading && hasNextPage ? (
          <Button
            className="userDashboardPage_continer4_1_1"
            onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
          >
            Load More
          </Button>
        ) : isLoading ? (
          <div className="loader">
            <Spinner animation="border" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default HOC(TestWithVideo);
