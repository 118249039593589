import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import PhoneInput from 'react-phone-number-input';
import { useNavigate } from "react-router-dom";
import { login, signUp } from "../../services/auth";
import "./LoginPage.css";

const LoginPage = () => {
  const [isLogin, setIsLogin] = useState(true);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [loginPhoneNumber, setLoginPhoneNumber] = useState();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) =>
    signUp({ payload: data, setIsLoading, setOtpSent, reset, setIsLogin });

  const onSubmitLogin = (data) =>
    login({
      payload: data,
      setIsLoading,
      setOtpSent,
      navigate,
    });

    const handleTabSwitch = (isLoginTab) => {
      const container = document.querySelector('.login_page__container1');
      const currentForm = document.querySelector('.login_page__container2__1');
      
      currentForm.classList.add('slide-out');
      
      setTimeout(() => {
        reset();
        setPhoneNumber('');
        setLoginPhoneNumber('');
        setIsLogin(isLoginTab);
        setOtpSent(false);
        setIsLoading(false);
        
        // Toggle the login-mode class
        if (isLoginTab) {
          container.classList.remove('login-mode');
        } else {
          container.classList.add('login-mode');
        }
        
        currentForm.classList.remove('slide-out');
        currentForm.classList.add('slide-in');
        
        setTimeout(() => {
          currentForm.classList.remove('slide-in');
        }, 300);
      }, 300);
    };

  return (
    <div className="login_page__container">
      <div className="animated-bg">
  {/* Books */}
  <div className="bg-element book" style={{ top: '10%', left: '5%' }}></div>
  <div className="bg-element book" style={{ top: '70%', left: '15%' }}></div>
  <div className="bg-element book" style={{ top: '30%', right: '10%' }}></div>
  <div className="bg-element book" style={{ bottom: '20%', right: '25%' }}></div>
  
  {/* Pencils */}
  <div className="bg-element pencil" style={{ top: '20%', left: '25%' }}></div>
  <div className="bg-element pencil" style={{ bottom: '30%', left: '75%' }}></div>
  <div className="bg-element pencil" style={{ top: '60%', right: '15%' }}></div>
  
  {/* Rulers */}
  <div className="bg-element ruler" style={{ top: '40%', left: '20%' }}></div>
  <div className="bg-element ruler" style={{ bottom: '25%', right: '30%' }}></div>
  <div className="bg-element ruler" style={{ top: '15%', right: '20%' }}></div>
  
  {/* Brains */}
  <div className="bg-element brain" style={{ top: '25%', left: '40%' }}></div>
  <div className="bg-element brain" style={{ bottom: '20%', left: '35%' }}></div>
  <div className="bg-element brain" style={{ top: '50%', right: '25%' }}></div>
  
  {/* Robots */}
  <div className="bg-element robot" style={{ top: '45%', left: '10%' }}></div>
  <div className="bg-element robot" style={{ bottom: '15%', right: '15%' }}></div>
  <div className="bg-element robot" style={{ top: '20%', right: '35%' }}></div>
</div>
      <div className="login_page__header">
        <h1 className="login_page__title">Start preparing with Digital Benchers !</h1>
        <p className="login_page__subtitle">Experience the true power of personalized learning</p>
      </div>
      <div className="login_page__container1">
        {isLogin ? (
          <>
            <div className="login_page__container2__1">
              <span>Get Started with Digital Benchers!
              <p>Start you personalized study plan now</p></span>
              <span>
                <Form
                  onSubmit={handleSubmit(onSubmit)}
                  className="login_page__container2__1__1"
                >
                  {otpSent ? (
                    <>
                      <div>
                        <Form.Group className="login_page__container2__1__1__1_1">
                          <Form.Control
                            className={`login_page__container2__1__1__1 ${
                              errors.otp ? "error" : ""
                            }`}
                            type="text"
                            placeholder="Enter OTP"
                            {...register("otp", {
                              required: true,
                              maxLength: 6,
                              minLength: 4,
                            })}
                          />
                        </Form.Group>
                        <p className="login_page__container2__1__1__1_2Resend">
                          Resend
                        </p>
                        <Form.Group className="login_page__container2__1__1__1_1VerifyC">
                          <button
                            disabled={isLoading}
                            className="login_page__container2__1__1__1_2Verify"
                          >
                            {isLoading ? "Verifying..." : "Verify"}
                          </button>
                        </Form.Group>
                      </div>
                    </>
                  ) : (
                    <Form.Group className="login_page__container2__1__1__1_2">
                      <Form.Control
                        className={`login_page__container2__1__1__1 ${
                          errors.fullName ? "errorfullName" : ""
                        }`}
                        type="text"
                        placeholder="Name"
                        {...register("fullName", { required: true })}
                      />
                      <PhoneInput
                        // international
                        defaultCountry="IN"
                        className={`login_page__container2__1__1__1 ${
                          errors.mobileNumber ? "errormobileNumber" : ""
                        }`}
                        placeholder="Enter Phone Number"
                        value={phoneNumber}
                        onChange={(value) => {
                          setPhoneNumber(value);
                          setValue('mobileNumber', value);
                        }}
                      />
                      
                      <Form.Control
                        className={`login_page__container2__1__1__1 ${
                          errors.email ? "error" : ""
                        }`}
                        type="email"
                        placeholder="Email"
                        {...register("email", { required: true })}
                      />
                      <Form.Select
                        className={`login_page__container2__1__1__1 ${
                          errors.hearAboutUs ? "errorhearAboutUs" : ""
                        }`}
                        {...register("hearAboutUs", { required: true })}
                      >
                        <option value="">Where Did You Hear About Us</option>
                        <option value="FaceBook">Facebook</option>
                        <option value="LinkedIn">LinkedIn</option>
                        <option value="YouTube">YouTube</option>
                        <option value="Websites">Websites</option>
                      </Form.Select>
                      <Form.Group className="login_page__container2__1__1__2r">
                        <Button disabled={isLoading} variant="primary" type="submit">
                          {isLoading ? "Loading..." : "Create Account"}
                        </Button>
                      </Form.Group>
                    </Form.Group>
                  )}
                </Form>
              </span>
            </div>
            <div className="login_page__container2__2">
              <div className="login_page__container2__2__1 has-account">
                <p className="welcome-text">Already have an account?</p>
                <Button
                  onClick={() => handleTabSwitch(false)}
                  className="login_page__container2__2__1__1"
                >
                  Log In
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="login_page__container2__2">
              <div className="login_page__container2__2__1">
                <p>Welcome Back!</p>
                <p className="welcome-text">Log in to continue your personalized learning journey.</p>
                <Button
                  onClick={() => handleTabSwitch(true)}
                  className="login_page__container2__2__1__1"
                >
                  Create Account
                </Button>
              </div>
            </div>
            <div className="login_page__container2__1">
              <span>Login To Your Account</span>
              <span>
                <Form
                  onSubmit={handleSubmit(onSubmitLogin)}
                  className="login_page__container2__1__1"
                >
                  {otpSent ? (
                    <>
                      <div>
                        <Form.Group className="login_page__container2__1__1__1_1">
                          <Form.Control
                            className={`login_page__container2__1__1__1 ${
                              errors.otp ? "error" : ""
                            }`}
                            type="number"
                            placeholder="Enter OTP"
                            {...register("otp", {
                              required: true,
                            })}
                          />
                        </Form.Group>
                        <Form.Group className="login_page__container2__1__1__1_1VerifyC">
                          <button
                            disabled={isLoading}
                            type="submit"
                            className="login_page__container2__1__1__1_2Verify"
                          >
                            {isLoading ? "Verifying..." : "Verify OTP"}
                          </button>
                        </Form.Group>
                      </div>
                    </>
                  ) : (
                    <>
                    <Form.Group className="login_page__container2__1__1__1_2r">
                    <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="IN"
                        className={`login_page__container2__1__1__1 ${
                          errors.mobileNumber ? "error" : ""
                        }`}
                        placeholder="Phone Number"
                        value={loginPhoneNumber}
                        onChange={(value) => {
                          setLoginPhoneNumber(value);
                          setValue('mobileNumber', value);
                        }}
                      />
                        </Form.Group>
                      <Form.Group className="login_page__container2__1__1__2r">
                        <Button disabled={isLoading} variant="primary" type="submit">
                          {isLoading ? "Loading..." : "Sign In"}
                        </Button>
                      </Form.Group>
                    </>
                  )}
                </Form>
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LoginPage;