import { useEffect, useState } from "react";
import styles from "./common_css/common.module.css";

export const BannerComponent = ({ image, alt }) => {
  const [svgContent, setSvgContent] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadSvg = async () => {
      setIsLoading(true);
      setSvgContent(null);
      setError(null);

      if (!image) {
        setError('No image provided');
        setIsLoading(false);
        return;
      }

      if (typeof image !== 'string') {
        setError('Invalid image path');
        setIsLoading(false);
        return;
      }

      if (image.endsWith('.svg')) {
        try {
          const response = await fetch(image);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const text = await response.text();
          const parser = new DOMParser();
          const doc = parser.parseFromString(text, 'image/svg+xml');
          const svg = doc.querySelector('svg');

          if (svg) {
            svg.classList.add('banner-svg');
            setSvgContent(svg.outerHTML);
          } else {
            setError('Invalid SVG content');
          }
        } catch (error) {
          console.error('Error loading SVG:', error);
          setError('Failed to load SVG');
        }
      }
      setIsLoading(false);
    };

    loadSvg();
  }, [image]);

  if (isLoading) {
    return (
      <div className="banner-container">
        <div className="banner-loading">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="banner-container">
        <div className="banner-error">{error}</div>
      </div>
    );
  }

  if (image?.endsWith('.svg') && svgContent) {
    return (
      <div 
        className="banner-container"
        dangerouslySetInnerHTML={{ __html: svgContent }}
      />
    );
  }

  if (image) {
    return (
      <div className="banner-container">
        <img
          src={image}
          className="banner-image"
          alt={alt || ""}
          loading="lazy"
        />
      </div>
    );
  }

  return <div className="banner-container" />;
};


export const UserBannerTopComponent = ({ image, imagecss, alt, divCSS }) => {
  return (
    <div className={divCSS}>
      {image && <img src={image} className={imagecss} alt={alt || ""} />}
    </div>
  );
};

export const UserBannerMultipleImages = ({
  images = [],
  alt = "",
  interval = 3000,
  divCss = "",
  imageCss = "",
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const switchImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const imageInterval = setInterval(switchImage, interval);

    return () => clearInterval(imageInterval);
  }, [images.length, interval]);

  return (
    <div className={divCss || styles.userBannerTop}>
      {images.length > 0 && (
        <img
          src={images[currentImageIndex]}
          className={imageCss || styles.userBannerTopImage}
          alt={alt || `Image ${currentImageIndex + 1}`}
        />
      )}
    </div>
  );
};
