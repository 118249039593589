import { useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import { UserBannerTopComponent } from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate, useParams } from "react-router-dom";
import "./Course.css";
import {
  DashboardUserCoursesTypeCard
} from "../../../components/common/Cards";
import { getRequest } from "../../../services/apiService";
import { endpoints } from "../../../services/endPoints";
const CoursePage2 = () => {
  const navigate = useNavigate();
  const courseUserCard = [
    {
      name: "Foundation Course For JEE",
      iconImage: images.fundationCourseUserImage,
      rating: 5,
      hours: "12 hours",
      leactures: "111 lectures",
      path: "/user/courses/foundation-course/1",
    },
    {
      name: "Foundation Course For JEE",
      iconImage: images.fundationCourseUserImage,
      rating: 5,
      hours: "12 hours",
      leactures: "111 lectures",
      path: "/user/courses/crash-course/1",
    },
    {
      name: "Foundation Course For JEE",
      iconImage: images.fundationCourseUserImage,
      rating: 5,
      hours: "12 hours",
      leactures: "111 lectures",
      path: "/user/courses/express-course/1",
    },
    {
      name: "Foundation Course For JEE",
      iconImage: images.fundationCourseUserImage,
      rating: 5,
      hours: "12 hours",
      leactures: "111 lectures",
      path: "/user/courses/react-course/1",
    },
    {
      name: "Foundation Course For JEE",
      iconImage: images.fundationCourseUserImage,
      rating: 5,
      hours: "12 hours",
      leactures: "111 lectures",
      path: "/user/courses/nextjs-course/1",
    },
    {
      name: "Foundation Course For JEE",
      iconImage: images.homePageCourseIcon6,
      rating: 5,
      hours: "12 hours",
      leactures: "111 lectures",
      path: "/user/courses/nextjs-course/1",
    },
  ];

  const [data, setData] = useState([]);
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getCourseByIdFilter,
      setIsLoading,
      params: { courseCategoryId: params.CourseType },
    }).then((res) => {
      
      setData(res?.data);
    });
  };

  useEffect(() => {
    getUserData();
  }, [params.CourseType]);
  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar link={"/user/dashboard"} />
        </div>
        <div className="user_container_width">
          <div className="CoursePage2_container">
            <p>{data?.[0]?.courseCategoryId?.name}</p>
            <div className="CoursePage2_div">
              {data &&
                data?.map((i, index) => (
                  <DashboardUserCoursesTypeCard
                    key={index}
                    name={i?.title}
                    iconImage={i?.courseImage?.[0]}
                    rating={i?.totalRating}
                    hours={i?.duration}
                    leactures={i?.lessons}
                    onClick={() => {
                      navigate(`/user/course/${params?.CourseType}/${i?._id}`);
                    }}
                  />
                ))}
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(CoursePage2);
