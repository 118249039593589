export const modifyLink = (link) => {
  return link.toLowerCase().replace(/\s+/g, "-");
};

export const formatLinks = (text) => {
  // Regular expression to match URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  // Replace URLs with <a> tags
  return text.split("\n").map((line, index) => {
    return (
      <p key={index}>
        {line.split(urlRegex).map((part, i) =>
          urlRegex.test(part) ? (
            <a href={part} target="_blank" rel="noopener noreferrer" key={i}>
              {part}
            </a>
          ) : (
            part
          )
        )}
      </p>
    );
  });
};
