import { useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import {
  BannerComponent,
  UserBannerTopComponent,
} from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate } from "react-router-dom";
import "./CapsuleCourse.css";
import { CapsuleUserCardComponent } from "../../../components/common/Cards";
import { Button } from "react-bootstrap";
import ProgressBar from "@ramonak/react-progress-bar";
import { getRequest } from "../../../services/apiService";
import { endpoints } from "../../../services/endPoints";
const CapsuleCourse = () => {
  const navigate = useNavigate();
  const handWrittenNotesUserCard = [
    {
      name: "Raj ",
      iconImage: images.userHandWrittenNotesImage2,
      title: "GATE SCA Digital Logic ",
      pages: 20,
      subTopics: 14,
      rating: 5,
    },
    {
      name: "Kamlesh Kumar",
      iconImage: images.userHandWrittenNotesImage2,
      title: "GATE SCA Digital Logic ",
      pages: 20,
      subTopics: 14,
      rating: 5,
    },
    {
      name: "Raj ",
      iconImage: images.userHandWrittenNotesImage2,
      title: "GATE SCA Digital Logic ",
      pages: 20,
      subTopics: 14,
      rating: 3,
    },
  ];
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getCapsuleCourse,
      setIsLoading,
    }).then((res) => {
      setData(res?.data);
      // setHasNextPage(res?.pagination?.hasNextPage);
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  const [purchasedCourses, setPurchasedCourses] = useState([]);
  const [isLoading2, setIsLoading2] = useState(false);

  const getPurchaseCourses = () => {
    getRequest({
      endpoint: endpoints.getMyCourses,

      params: { type: "CapsuleCourse", limit: 1000 },
      setIsLoading2,
    }).then((res) => {
      setPurchasedCourses(res?.data);
    });
  };

  useEffect(() => {
    getPurchaseCourses();
    getUserData();
  }, []);

  const [bannerData, setBannerData] = useState({});
  const getBanner = () => {
    const params = {
      position: "TOP",
      type: "CapsuleCourse",
    };
    getRequest({
      endpoint: endpoints.getHomePageBanners,
      setIsLoading,
      params,
    }).then((res) => {
      setBannerData(res?.data);
    });
  };
  useEffect(() => {
    getBanner();
  }, []);
  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar
            DashboardName={"Capsule Course Analysis"}
            link={"/user/my-sucess-roadmap"}
          />
        </div>
        <div className="user_container_width">
          <div>
            <div>
              {bannerData && (
                <BannerComponent
                  image={
                    bannerData?.image?.[bannerData?.image?.length - 1] ||
                    images.userHandWrittenNotesImage
                  }
                  imagecss={"userHandWrittenNotesBannerImage"}
                  alt="alttext"
                />
              )}
            </div>
            <div className="mt-2 mb-2">
              {purchasedCourses.length > 0 && (
                <p className="dashboardPage_continueWhereYouLeft_0">
                  Your Purchased Capsule Courses
                </p>
              )}
              {purchasedCourses.length > 0 &&
                purchasedCourses?.map((i, index) => (
                  <div className="dashboardPage_continueWhereYouLeft_1 user_container_width">
                    <div className="dashboardPage_continueWhereYouLeft_1_1">
                      <img src={images.userDashboardTopBanner} alt="image" />
                    </div>
                    <div className="dashboardPage_continueWhereYouLeft_1_2">
                      <span>{i?.capsuleCourse?.title}</span>
                      <span>
                        <ProgressBar
                          completed={i?.progress || 0}
                          labelColor="gray"
                          bgColor="linear-gradient(90deg, #A37BF5, #F3A952) "
                          isLabelVisible={false}
                        />
                      </span>
                    </div>
                    <div className={"dashboardPage_continueWhereYouLeft_1_3"}>
                      <Button
                        onClick={() =>
                          navigate(
                            `/user/capsule-course/${i?.capsuleCourse?._id}`
                          )
                        }
                      >
                        Start
                      </Button>
                    </div>
                  </div>
                ))}
            </div>
            <div>
              <div className="userHandWrittenNotesCards gridLayout4">
                {data &&
                  data?.map((i, index) => (
                    <CapsuleUserCardComponent
                      name={i.name}
                      image={i.courseImage?.[i?.courseImage?.length - 1]}
                      title={i.title}
                      pages={i.count || 0}
                      subtopics={i.subTopics || ""}
                      rating={i.totalRating || 0}
                      key={index}
                      item={i}
                      path={`/user/capsule-course/${i._id}`}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(CapsuleCourse);
