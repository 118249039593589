import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import images from "../../utils/images";
import "./css/Navbar.css";

const Navbar = ({ isCSS }) => {
  const navigate = useNavigate();
  const handleInitialPageClick = ({ page }) => {
    localStorage.setItem("startingPage", page);
    navigate(`/signup`);
  };
  return (
    <div className={isCSS ? "navbar" : ""}>
      <div className="navbar_container fixMaxWidth gridLayoutNavbar">
      <div className="navbar_logo_container">
          <div className="navbar_logo_wrapper">
            <img src={images?.navBarLogo} alt="Digital Benchers" style={{ animation: 'none' }} />
          </div>
        </div>
        <div className="navbar_search_container">
          <div className="navbar_search_container_1">
            <div>
              <Form.Control
                type="search"
                placeholder="Search courses, test series and exams"
                className="navbar_search_container_1_1"
                aria-label="Search courses, test series and exams"
              />
            </div>
            <div>
              <Icon
                icon="ic:round-search"
                className="navbar_search_container_1_2"
              />
            </div>
          </div>
        </div>
        <div className="navbar_user_container">
          <span className="navbar_user_container_1">
            <img src={images?.navBarGiftIcon} alt="icon" />
          </span>
          <span className="navbar_user_container_2">
            <Button onClick={() => handleInitialPageClick({ page: "user/home" })}>
              Login
            </Button>
          </span>
          <span className="navbar_user_container_3">
            <Button variant="primary">Get the App</Button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
