import { useContext, useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { Icon } from "@iconify/react/dist/iconify.js";
import images from "../../../utils/images";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import UserTestNavBar from "./UserTestNavBar";
import "./UserTestDiv.css";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from "chart.js";
import { getRequest } from "../../../services/apiService";
import { endpoints } from "../../../services/endPoints";
import { UserContext } from "../../../Context/Context";

ChartJS.register(Tooltip, Legend, ArcElement);
const UserTestDiv = () => {
  const Items = [
    "OverView",
    "Teacher Notes",
    "Q & A",
    "Hand written notes by topper",
  ];
  const testwithVideoUserCard = [
    {
      name: "RRB NTPC (CBT 1 + CBT 2) 2024 Mock Test Series",
      iconImage: images.userTestWithVideoImage,
      description: "🌐 English, Hindi + 6 More",
      descriptionArray: ["1100 Total Tests", "10 Free tests"],
      courseText: "Start Foundation Course",
      pointsArray: [
        "96 Current General knowledge CT",
        "269 Chapter test",
        "+756 more test",
      ],
      users: 80,
    },
    {
      name: "RRB NTPC (CBT 1 + CBT 2) 2024 Mock Test Series",
      iconImage: images.userTestWithVideoImage,
      description: "🌐 English, Hindi + 6 More",
      descriptionArray: ["1100 Total Tests", "10 Free tests"],
      courseText: "Start Foundation Course",
      pointsArray: [
        "96 Current General knowledge CT",
        "269 Chapter test",
        "+756 more test",
      ],
      users: 80,
    },
  ];

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  const [selectedItem, setSelectedItem] = useState(0);
  const navigate = useNavigate();
  const [dataItem, setDataItem] = useState([]);
  const { testId, testSeriesFileId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const userData = useContext(UserContext);
  const location=useLocation();
  const {isMainTest}=location.state||{}
  const getUserData = () => {

    const params = {
      testId: testId,
      // testSeriesId: testSeriesFileId,
    };
    
    if(isMainTest) params.mainTestSeriesId = testSeriesFileId;
    if(!isMainTest) params.testSeriesId = testSeriesFileId;
    getRequest({
      endpoint: endpoints.getTestViewDetails,
      setIsLoading,
      params,
    }).then((res) => {
      setDataItem(res.data);
      console.log(res.data);
    });
  };

  useEffect(() => {
    getUserData();
  }, []);
  const data = {
    labels: ["Not visited", "unattempt", "correct", "wrong"],
    datasets: [
      {
        data: [
          dataItem?.overview?.notVisited || 0,
          dataItem?.overview?.unattempted || 0,
          dataItem?.overview?.correct || 0,
          dataItem?.overview?.wrong || 0,
        ],
        backgroundColor: ["#f2c94c", "#b2acac", "#2ecc71", "#e74c3c"],
        hoverBackgroundColor: ["#f2c94c", "#b2acac", "#2ecc71", "#e74c3c"],
      },
    ],
  };
  return (
    <div>
      <div>
        <UserTestNavBar />
      </div>
      <div className="myWishlistContainer">
        <div className="CoursePage5MainContainer">
          <div className="UserTestDivContainer">
            <div className="UserTestDivContainer1">
              <div className="UserTestDivContainer1_1">
                <div>
                  <img src={images.UserTestTrophy} alt="userTestImage" />
                </div>
                <div className="UserTestDivContainer1_1_1">
                  <span>Congratulations! 🥳</span>
                  <span>{userData?.user?.fullName || ""}</span>
                  <span>
                    You fell short of the cut-off. keep striving for
                    improvement! Your score{" "}
                    {dataItem?.currentUserDetails?.marksObtained || 0} | Cutoff:{" "}
                    <span>90.77</span>
                  </span>
                </div>
              </div>
              <div className="UserTestDivContainer1_2">
                <div className="UserTestDivContainer1_2_1">
                  <span className="UserTestDivContainer1_2_1_1">
                    <span className="UserTestDivContainer1_2_1_1_1">
                      <span>Accuracy</span>
                      {console.log(dataItem)}
                      <span>
                        {dataItem?.currentUserDetails?.accuracy || 0} %
                      </span>
                    </span>
                    <span>
                      <img src={images.userTestUserPageImage2} alt="images1" />
                    </span>
                  </span>
                  <span></span>
                </div>
                <div className="UserTestDivContainer1_2_1">
                  <span className="UserTestDivContainer1_2_1_1">
                    <span className="UserTestDivContainer1_2_1_1_1">
                      <span>Rank</span>
                      <span>
                        {dataItem?.currentUserDetails?.rank || 0}/{" "}
                        {dataItem?.totalUsers || 0}
                      </span>
                    </span>
                    <span>
                      <img src={images.userTestUserPageImage3} alt="images1" />
                    </span>
                  </span>
                  <span></span>
                </div>
                <div className="UserTestDivContainer1_2_1">
                  <span className="UserTestDivContainer1_2_1_1">
                    <span className="UserTestDivContainer1_2_1_1_1">
                      <span>Percentage</span>
                      <span>
                        {dataItem?.currentUserDetails?.percentage || 0} %
                      </span>
                    </span>
                    <span>
                      <img src={images.userTestUserPageImage4} alt="images1" />
                    </span>
                  </span>
                  <span></span>
                </div>
                <div className="UserTestDivContainer1_2_1">
                  <span className="UserTestDivContainer1_2_1_1">
                    <span className="UserTestDivContainer1_2_1_1_1">
                      <span>Percentile</span>
                      <span>
                        {dataItem?.currentUserDetails?.percentile || 0} %
                      </span>
                    </span>
                    <span>
                      <img src={images.userTestUserPageImage5} alt="images1" />
                    </span>
                  </span>
                  <span></span>
                </div>
                <div className="UserTestDivContainer1_2_1">
                  <span className="UserTestDivContainer1_2_1_1">
                    <span className="UserTestDivContainer1_2_1_1_1">
                      <span>AVG Time/Ques</span>
                      <span>
                        {dataItem?.currentUserDetails?.averageTime || 0} Min
                      </span>
                    </span>
                    <span>
                      <img src={images.userTestUserPageImage1} alt="images1" />
                    </span>
                  </span>
                  <span></span>
                </div>
                <div className="UserTestDivContainer1_2_1">
                  <span className="UserTestDivContainer1_2_1_1">
                    <span className="UserTestDivContainer1_2_1_1_1">
                      <span>Score</span>
                      <span>
                        {dataItem?.currentUserDetails?.marksObtained || 0}/{" "}
                        {dataItem?.currentUserDetails?.totalMarks || 0}
                      </span>
                    </span>
                    <span>
                      <img src={images.userTestUserPageImage1} alt="images1" />
                    </span>
                  </span>
                  <span></span>
                </div>
              </div>
            </div>
            <div className="UserTestDivContainer2Main gridLayout3">
              <div className="UserTestDivContainer2_1">
                <p>Leaderboard</p>

                {/* <p>
                  <hr />
                </p> */}
                {dataItem?.leaderboard?.map((item, index) => (
                  <>
                    <p className="UserTestDivContainer2_1_1">
                      <span>
                        <span>{item?.name}</span>
                        <span>Marks : {item?.marks}</span>
                      </span>
                      <span>
                        {" "}
                        {item?.rank === 1 ||
                        item?.rank === 2 ||
                        item?.rank === 3 ? (
                          <Icon
                            icon={
                              item?.rank === 1
                                ? "noto:1st-place-medal"
                                : item?.rank === 2
                                ? "noto:2nd-place-medal"
                                : "noto:3rd-place-medal"
                            }
                            style={{ width: "30px", height: "30px" }}
                          />
                        ) : (
                          `${item?.rank}`
                        )}
                      </span>
                    </p>
                    <hr />
                  </>
                ))}
                {/* <p className="UserTestDivContainer2_1_1">
                  <span>
                    <span>Sameer</span>
                    <span>Marks : 230/300</span>
                  </span>
                  <span>
                    {" "}
                    <Icon
                      icon="noto:1st-place-medal"
                      style={{ width: "30px", height: "30px" }}
                    />
                  </span>
                </p>
                <hr />
                <p className="UserTestDivContainer2_1_1">
                  <span>
                    <span>Sameer</span>
                    <span>Marks : 230/300</span>
                  </span>
                  <span>
                    {" "}
                    <Icon
                      icon="noto:2nd-place-medal"
                      style={{ width: "30px", height: "30px" }}
                    />
                  </span>
                </p>
                <hr />
                <p className="UserTestDivContainer2_1_1">
                  <span>
                    <span>Sameer</span>
                    <span>Marks : 230/300</span>
                  </span>
                  <span>
                    {" "}
                    <Icon
                      icon="noto:3rd-place-medal"
                      style={{ width: "30px", height: "30px" }}
                    />
                  </span>
                </p>
                <hr />
                <p className="UserTestDivContainer2_1_1">
                  <span>
                    <span>Sameer</span>
                    <span>Marks : 230/300</span>
                  </span>
                  <span> 4</span>
                </p>
                <hr /> */}
              </div>
              <div
                style={{ paddingBottom: "2rem" }}
                className="UserTestDivContainer2_1"
              >
                <p>OverView</p>

                <div>
                  <div
                    style={{
                      width: "300px",
                      height: "300px",
                      margin: "0 auto",
                      padding: "3rem",
                    }}
                  >
                    <Pie data={data} options={options} />
                  </div>
                  <div className="gridLayout2 pb-4 pt-2">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "10px",
                      }}
                    >
                      <span
                        style={{
                          width: "15px",
                          height: "15px",
                          backgroundColor: "#f2c94c",
                          display: "inline-block",
                          borderRadius: "50%",
                          marginRight: "5px",
                        }}
                      ></span>
                      <span>Not visited</span>
                    </div>
                    <div>
                      <span
                        style={{
                          width: "15px",
                          height: "15px",
                          backgroundColor: "#b2acac",
                          display: "inline-block",
                          borderRadius: "50%",
                          marginRight: "5px",
                        }}
                      ></span>
                      <span>unattempt</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "10px",
                      }}
                    >
                      <span
                        style={{
                          width: "15px",
                          height: "15px",
                          backgroundColor: "#2ecc71",
                          display: "inline-block",
                          borderRadius: "50%",
                          marginRight: "5px",
                        }}
                      ></span>
                      <span>correct</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          width: "15px",
                          height: "15px",
                          backgroundColor: "#e74c3c",
                          display: "inline-block",
                          borderRadius: "50%",
                          marginRight: "5px",
                        }}
                      ></span>
                      <span>wrong</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="UserTestDivContainer2_1">
                <p>You vs Topper</p>

                {/* <p>
                  <hr />
                </p> */}
                <p className="UserTestDivContainer2_1_12">
                  <span>Name</span>
                  <span>You</span>
                  <span>Topper</span>
                </p>
                <hr />
                <p className="UserTestDivContainer2_1_12">
                  <span style={{ color: "#6090F7" }}>Score</span>
                  <span style={{ color: "#A3A3A3" }}>
                    {dataItem?.comparison?.yourMarks || 0}
                  </span>
                  <span style={{ color: "#A3A3A3" }}>
                    {dataItem?.comparison?.topperMarks || 0}
                  </span>
                </p>
                <hr />
                <p className="UserTestDivContainer2_1_12">
                  <span style={{ color: "#6090F7" }}>Accuracy</span>
                  <span style={{ color: "#A3A3A3" }}>
                    {dataItem?.comparison?.yourAccuracy || 0} %
                  </span>
                  <span style={{ color: "#A3A3A3" }}>
                    {dataItem?.comparison?.topperAccuracy || 0} %
                  </span>
                </p>
                <hr />
                <p className="UserTestDivContainer2_1_12">
                  <span style={{ color: "#6090F7" }}>Correct</span>
                  <span style={{ color: "#A3A3A3" }}>
                    {dataItem?.comparison?.yourCorrect || 0}
                  </span>
                  <span style={{ color: "#A3A3A3" }}>
                    {dataItem?.comparison?.topperCorrect || 0}
                  </span>
                </p>
                <hr />
                <p className="UserTestDivContainer2_1_12">
                  <span style={{ color: "#6090F7" }}>incorrect</span>
                  <span style={{ color: "#A3A3A3" }}>
                    {dataItem?.comparison?.yourIncorrect || 0}
                  </span>
                  <span style={{ color: "#A3A3A3" }}>
                    {dataItem?.comparison?.topperIncorrect || 0}
                  </span>
                </p>
                <hr />
                <p className="mt-6 UserTestDivContainer2_1_12">
                  <span style={{ color: "#6090F7" }}>Total Time</span>
                  <span style={{ color: "#A3A3A3" }}>
                    {dataItem?.comparison?.yourTotalTime || 0}
                  </span>
                  <span style={{ color: "#A3A3A3" }}>
                    {dataItem?.comparison?.topperTotalTime || 0}
                  </span>
                </p>
                <hr />
              </div>
            </div>
            <div className="UserTestDivContainer3Main">
              <p>Section wise distribution</p>
              <hr />
              <p className="UserTestDivContainer3_1">
                <span>
                  <span style={{ color: "#7DE079" }}>Attempted</span>
                  {/* <span>Physics section - A</span> */}
                </span>
                <span>
                  <span style={{ color: "#FD5602" }}>Correct</span>
                  {/* <span>Chemistry Section - B</span> */}
                </span>
                <span>
                  <span style={{ color: "#FFC851" }}>Incorrect</span>
                  {/* <span>Chemistry Section - A</span> */}
                </span>
                <span>
                  <span style={{ color: "#9747FF" }}>Time/Ques</span>
                  {/* <span>Mathematics Section - A</span> */}
                </span>
              </p>
              <p className="UserTestDivContainer3_2">
                You attempted
                <span> 10% questions correct in physic section - A</span>
                and
                <span> 20% questions wrong in chemistry section - B.</span>
                Keep practicing to increase your score!
              </p>
              <p className="UserTestDivContainer3_3">
                <span>Your detailed section performance is shown below</span>
                <span>
                  <Table bordered responsive className="mt-3">
                    <thead>
                      <tr>
                        <th>Section</th>
                        <th>Attempted</th>
                        <th>Correct</th>
                        <th>Accuracy</th>
                        <th>Time </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataItem?.sectionWiseData?.map((item, index) => (
                        <tr key={index}>
                          <td>{item.sectionName}</td>
                          <td>{item.attempted}</td>
                          <td>{item.correct}</td>
                          <td>{item.accuracy}</td>
                          <td>{item.time}</td>
                        </tr>
                      ))}
                      {/* <tr>
                        <td>Mathematics section - A</td>
                        <td>10</td>
                        <td>2</td>
                        <td>20%</td>
                        <td>30 min</td>
                      </tr> */}
                    </tbody>
                  </Table>
                </span>
              </p>
            </div>
            <div className="UserTestDivContainer3Main">
              <p>Attempted efficiency </p>
              <hr />
              <p className="UserTestDivContainer3_1">
                <span>
                  <span style={{ color: "#D9C4FF" }}>Attempted</span>
                  <span>8 of 75</span>
                </span>
                <span>
                  <span style={{ color: "#7DE079" }}>Correct</span>
                  <span>2 of 75</span>
                </span>
                <span>
                  <span style={{ color: "#FFC851" }}>Incorrect</span>
                  <span>6 of 75</span>
                </span>
                <span>
                  <span style={{ color: "#9747FF" }}>Time/Ques</span>
                  <span>0 min 8 sec</span>
                </span>
              </p>
              <p className="UserTestDivContainer3_2r">
                You spent <span>0 Min</span> on deciding the questions you don’t
                want to attempt.
              </p>
              <p className="UserTestDivContainer3_3">
                <span>Your detailed section performance is shown below</span>
                <span>
                  <Form.Control as="textarea" rows={5} />
                </span>
              </p>
              <p className="UserTestDivContainer5_1">
                <span>
                  <span>
                    <Icon
                      icon="icon-park-outline:dot"
                      style={{
                        color: "#FD5602",
                        width: "25px",
                        height: "25px",
                      }}
                    />
                  </span>
                  <span>Time spent on un-attempted</span>
                </span>
                <span>
                  <span>
                    <Icon
                      icon="icon-park-outline:dot"
                      style={{
                        color: "#7DE079",
                        width: "25px",
                        height: "25px",
                      }}
                    />
                  </span>
                  <span>Time utilized</span>
                </span>
              </p>
            </div>
            <div className="UserTestDivContainer5rk">
              <Button
                onClick={() => {
                  if (dataItem?.sectionWiseData) {
                    navigate("/test-with-video/page7", {
                      state: { dataItem: dataItem },
                    });
                  }
                }}
              >
                View detail solution
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTestDiv;
