import React, { useEffect, useRef } from 'react';

const ScrollAnimation = ({ 
  children, 
  direction = 'up', 
  delay = 0,
  duration = 1200,
  distance = 100,
  threshold = 0.2 
}) => {
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Add both animate-in and the direction-specific class
            entry.target.classList.add('animate-in');
            entry.target.style.visibility = 'visible';
          }
        });
      },
      { 
        threshold: threshold,
        rootMargin: '0px 0px -100px 0px' // Starts animation slightly before element comes into view
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [threshold]);

  const getInitialStyles = () => {
    const baseStyles = 'invisible transform transition-all ease-out';
    
    switch (direction) {
      case 'up':
        return `${baseStyles} translate-y-${distance}`;
      case 'down':
        return `${baseStyles} -translate-y-${distance}`;
      case 'left':
        return `${baseStyles} -translate-x-${distance}`;
      case 'right':
        return `${baseStyles} translate-x-${distance}`;
      case 'fade':
        return `${baseStyles}`;
      default:
        return `${baseStyles} translate-y-${distance}`;
    }
  };

  return (
    <div
      ref={elementRef}
      className={getInitialStyles()}
      style={{
        transitionDuration: `${duration}ms`,
        transitionDelay: `${delay}ms`,
        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)'
      }}
    >
      {children}
    </div>
  );
};

export default ScrollAnimation;