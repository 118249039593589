import { showNotification } from "../components/common/APIComponents";
import api from "./api";

export const signUp = async ({
  payload,
  setIsLoading,
  setOtpSent,
  setIsLogin,
}) => {
  try {
    const response = await api.post("/user/signup", payload);

    showNotification({
      type: "success",
      message: "User created successfully",
    });
    return response.data;
  } catch (error) {
    // throw error.response?.data?.message || error.message;
    showNotification({
      type: "error",
      message: error.response?.data?.message || error.message,
    });
    setIsLogin(false);
  }
};

export const login = async ({
  payload,
  setIsLoading,
  setOtpSent,
  navigate,
}) => {
  setIsLoading(true);

  try {
    if (payload.otp && payload.mobileNumber) {
      const userIdLogin = localStorage.getItem("userIdLogin");

      const newPayload = {
        otp: payload.otp,
      };
      const response = await api.post(`/user/${userIdLogin}`, newPayload);
      
      showNotification({
        type: "success",
        message: response.data.message || "Login successful",
      });
      localStorage.setItem("authToken", response.data?.data?.token);
      // navigate("/select-goals");
      window.location.href = "/select-goals";
      return response.data;
    } else if (payload.mobileNumber) {
      const response = await api.post("/user/loginWithPhone", payload);

      setOtpSent(true);
      localStorage.setItem("userIdLogin", response.data?.data?.id);

      showNotification({
        type: "success",
        message: response.data?.data?.otp || "OTP sent successfully",
      });
      return response.data;
    } else {
      showNotification({
        type: "error",
        message: "Mobile number is required",
      });
    }
  } catch (error) {
    
    showNotification({
      type: "error",
      message: error.response?.data?.message || error.message,
    });
  } finally {
    if (setIsLoading) setIsLoading(false);
  }
};

export const getUserProfile = async () => {
  try {
    const response = await api.get("/user/profile");
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};
