import { useEffect, useState } from "react";
import HOC from "../../../components/layout/HOC";
import images from "../../../utils/images";
import { UserBannerTopComponent } from "../../../components/common/Banners";
import { UserMenuBar } from "../../../components/common/MenuBar";
import { useNavigate } from "react-router-dom";
import "./Settings.css";
import {
  CapsuleUserCardComponent,
  CapsuleUserCardComponentTranscript,
  SwiperComponentSettings,
} from "../../../components/common/Cards";
import { Button, Form } from "react-bootstrap";
import TimelineComponent from "../../../components/common/TimelineComponent";
import { useForm } from "react-hook-form";
import { getRequest, putRequest } from "../../../services/apiService";
import { endpoints } from "../../../services/endPoints";
const Settings = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const { register, handleSubmit } = useForm();
  const [timeLineData, setTimeLineData] = useState([]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setPreviewImage(URL.createObjectURL(file));
  };
  const handWrittenNotesUserCard = [
    {
      name: "Toppers Name",
      iconImage: images.userHandWrittenNotesImage2,
      title: "GATE SCA Digital Logic ",
      pages: 20,
      subTopics: 14,
      rating: 5,
    },
  ];
  const slides1 = [
    {
      name: "Profile",
    },
    {
      name: "My Exams",
    },
    {
      name: "Transactions",
    },
    {
      name: "My Dashboard",
    },
    {
      name: "My Weekly Timeline",
    },
  ];
  const allDashboard = [
    {
      name: "My Sucess Roadmap",
      image: images.userSettingsImage1,
      path: "/user/my-sucess-roadmap",
    },
    {
      name: "Videos Analysis",
      image: images.userSettingsImage2,
      path: "/user/videos-analysis",
    },
    {
      name: "Practice Analysis",
      image: images.userSettingsImage3,
      path: "/user/practice-analysis",
    },
    {
      name: "Tests Analysis",
      image: images.userSettingsImage4,
      path: "/user/tests-analysis",
    },
    {
      name: "Handwritten Notes by Toppers Analysis",
      image: images.userSettingsImage5,
      path: "/user/handwritten-notes-by-toppers-analysis",
    },
    {
      name: "My skills analysis",
      image: images.userSettingsImage6,
      path: "/user/practice-analysis",
    },
    {
      name: "Capsule course analysis",
      image: images.userSettingsImage7,
      path: "/user/capsule-course-analysis",
    },
  ];
  const timelineItems = [
    {
      date: "14-Oct-2024",
      title: "Weekly Time Spent",
      description: "1 min 31 secs",
      videosWatched: 0,
      practiceActivities: 0,
      testsTaken: 1,
      iconBackground: "#0F19B6",
      icon: (
        <span role="img" aria-label="timer">
          ⏳
        </span>
      ),
    },
    {
      date: "07-Oct-2024",
      title: "Weekly Time Spent",
      description: "5 mins 15 secs",
      videosWatched: 2,
      practiceActivities: 1,
      testsTaken: 0,
      iconBackground: "#0F19B6",
      icon: (
        <span role="img" aria-label="timer">
          ⏳
        </span>
      ),
    },
    {
      date: "30-Sep-2024",
      title: "Weekly Time Spent",
      description: "12 mins 20 secs",
      videosWatched: 1,
      practiceActivities: 3,
      testsTaken: 1,
      iconBackground: "#0F19B6",
      icon: (
        <span role="img" aria-label="timer">
          ⏳
        </span>
      ),
    },
    {
      date: "23-Sep-2024",
      title: "Weekly Time Spent",
      description: "8 mins 45 secs",
      videosWatched: 3,
      practiceActivities: 2,
      testsTaken: 2,
      iconBackground: "#0F19B6",
      icon: (
        <span role="img" aria-label="timer">
          ⏳
        </span>
      ),
    },
  ];
  const [slectedSlide, setSelectedSlide] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [purchasedCourses, setPurchasedCourses] = useState([]);
  const [purchasedSkills, setPurchasedSkills] = useState([]);
  const [isLoading2, setIsLoading2] = useState(false);

  const getTimeLineData = () => {
    getRequest({
      endpoint: endpoints.getTimeLineData,
      setIsLoading,
    }).then((res) => {
      const formatedData = res?.data[0]?.dailyData.map((item) => ({
        ...item,
        date: item.date.split("T")[0],
        videosWatched: item.totalVideosWatched,
        practiceActivities: item.totalPracticeActivities,
        testsTaken: item.totalTestsTaken,

      }))
      setTimeLineData(formatedData);

    });
  };
  const getUserData = () => {
    getRequest({
      endpoint: endpoints.getUserProfile,
      setIsLoading,
    }).then((res) => {
      setUserData(res?.data?.user);
    });
  };

  useEffect(() => {
    getUserData();
    getTimeLineData();
  }, [slectedSlide]);
  const onSubmit = (data) => {
    console.log(image);
    if (image) {
      const imagePayload = new FormData();
      imagePayload.append("image", image);
      console.log("FormData entries:", Array.from(imagePayload.entries()));

      putRequest({
        endpoint: endpoints.updateUserProfilePic,
        data: imagePayload,
        setIsLoading,
      })
        .then((res) => {
          getUserData();
          navigate("/user/settings");
        })
        .catch((err) => {
          console.error("Update failed:", err);
        });
    }

    if (data.addressDetails.streetAddress === "")
      data.addressDetails.streetAddress = userData.addressDetails.streetAddress;
    if (data.addressDetails.city === "")
      data.addressDetails.city = userData.addressDetails.city;
    if (data.addressDetails.state === "")
      data.addressDetails.state = userData.addressDetails.state;
    if (data.addressDetails.zip === "")
      data.addressDetails.zipCode = userData.addressDetails.zipCode;
    if (data.addressDetails.country === "")
      data.addressDetails.country = userData.addressDetails.country;

    putRequest({
      endpoint: endpoints.updateUserProfile,
      data: data,
      setIsLoading,
    })
      .then((res) => {
        getUserData();
        return;
        navigate("/user/settings");
      })
      .catch((err) => {
        console.error("Update failed:", err);
      });
  };

  const getPurchasedStudyPlanner = () => {
    getRequest({
      endpoint: endpoints.getMyCourses,

      params: { limit: 100, type: "Course" },
      setIsLoading2,
    }).then((res) => {
      setPurchasedCourses(res?.data);
    });
  };

  const getPurchasedSkills = () => {
    getRequest({
      endpoint: endpoints.getMyCourses,
      params: { limit: 100, type: "Skills" },
      setIsLoading2,
    }).then((res) => {
      console.log(res?.data);
      setPurchasedSkills(res?.data);
    });
  };
  // const getStud
  useEffect(() => {
    getPurchasedStudyPlanner();
    getPurchasedSkills();
  }, []);

  return (
    <div className="user_container ">
      <div>
        <div>
          <UserBannerTopComponent
            divCSS="UserMainTopBannerDiv"
            imagecss={"UserMainTopBannerDivImage"}
            image={images.userDashboardTopBanner}
          />
        </div>
        <div className="user_container_width">
          <UserMenuBar name={"Settings"} link={"/user/dashboard"} />
        </div>
        <div className="user_container_width">
          <div className="userSettingsCardsContainer">
            <div className="userHandWrittenNotesCardsSettings">
              <SwiperComponentSettings
                slides={slides1}
                setSelectedSlide={setSelectedSlide}
                slectedSlide={slectedSlide}
              />
            </div>
            {slectedSlide === 0 && (
              <div>
                <div className="userSettingsSlide1ImageDiv">
                  {userData?.image && (
                    <img
                      src={previewImage || userData?.image}
                      alt="userImage"
                    />
                  )}
                  {!isLoading && (
                    <input
                      className="d-none"
                      type="file"
                      id="userSettingsSlide1Image"
                      onChange={handleImageChange}
                    />
                  )}
                  <Button
                    variant="primary"
                    className="userSettingsSlide1Button"
                    onClick={(e) => {
                      e.preventDefault();
                      document
                        .getElementById("userSettingsSlide1Image")
                        .click();
                    }}
                  >
                    Change Image
                  </Button>
                </div>

                <div className="userSettingsSlide1ButtonDiv2">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="userSettingsSlide1InputLabel">
                        First Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="userSettingsSlide1Input"
                        {...register("firstName")}
                        defaultValue={userData?.firstName}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="userSettingsSlide1InputLabel">
                        Last Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="userSettingsSlide1Input"
                        {...register("lastName")}
                        defaultValue={userData?.lastName}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="userSettingsSlide1InputLabel">
                        Phone Number
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="userSettingsSlide1Input"
                        {...register("mobileNumber")}
                        defaultValue={userData?.mobileNumber}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="userSettingsSlide1InputLabel">
                        Email
                      </Form.Label>
                      <Form.Control
                        type="email"
                        className="userSettingsSlide1Input"
                        {...register("email")}
                        defaultValue={userData?.email}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="userSettingsSlide1InputLabel">
                        How did you find us?
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="userSettingsSlide1Input"
                        {...register("hearAboutUs")}
                        defaultValue={userData?.hearAboutUs}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="userSettingsSlide1InputLabel">
                        Currently pursuing
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="userSettingsSlide1Input"
                        {...register("currentlyPursuing")}
                        defaultValue={userData?.currentlyPursuing}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="userSettingsSlide1InputLabel">
                        Address
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="userSettingsSlide1Input"
                        {...register("addressDetails.streetAddress")}
                        defaultValue={userData?.addressDetails?.streetAddress}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="userSettingsSlide1InputLabel">
                        State
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="userSettingsSlide1Input"
                        {...register("addressDetails.state")}
                        defaultValue={userData?.addressDetails?.state}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="userSettingsSlide1InputLabel">
                        City
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="userSettingsSlide1Input"
                        {...register("addressDetails.city")}
                        defaultValue={userData?.addressDetails?.city}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="userSettingsSlide1InputLabel">
                        Post Code
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="userSettingsSlide1Input"
                        {...register("addressDetails.zipCode")}
                        defaultValue={userData?.addressDetails?.zipCode}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="userSettingsSlide1InputLabel">
                        Country
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="userSettingsSlide1Input"
                        {...register("addressDetails.country")}
                        defaultValue={userData?.addressDetails?.country}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 mt-4 d-flex justify-content-between">
                      <Button
                        variant="primary"
                        className="userSettingsSlide1Button2"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? "Saving..." : "Save"}
                      </Button>
                    </Form.Group>
                  </Form>
                </div>
              </div>
            )}
            {slectedSlide === 1 && (
              <div className="userSettingsDiv2">
                <div>
                  <p className="userSettingsDiv2Comp1text">
                    Your Capsule Courses
                  </p>
                  <div className="userHandWrittenNotesCards gridLayout4">
                    {handWrittenNotesUserCard.map((i, index) => (
                      <CapsuleUserCardComponent
                        key={index}
                        name={i.name}
                        image={i.iconImage}
                        title={i.title}
                        pages={i.pages}
                        subtopics={i.subTopics}
                        rating={i.rating}
                      />
                    ))}
                  </div>
                </div>
                <div>
                  <p className="userSettingsDiv2Comp1text">
                    Your Skill Courses
                  </p>
                  <div className="userHandWrittenNotesCards gridLayout4">
                    {handWrittenNotesUserCard.map((i, index) => (
                      <CapsuleUserCardComponent
                        name={i.name}
                        image={i.iconImage}
                        title={i.title}
                        pages={i.pages}
                        subtopics={i.subTopics}
                        rating={i.rating}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
            {slectedSlide === 2 && (
              <div className="userSettingsDiv3">
                {console.log(purchasedCourses)}
                {purchasedCourses?.length > 0 || purchasedSkills?.length > 0 ? (
                  <div>
                    <div className="userSettingsDiv2">
                      <div>
                        <p className="userSettingsDiv2Comp1text">
                          Your Courses
                        </p>
                        <div className="userHandWrittenNotesCards gridLayout4">
                          {purchasedCourses?.map((i, index) => (
                            <CapsuleUserCardComponent
                              key={index}
                              // name={i.course?.title||""}
                              image={i.course?.courseImage?.[0]||""}
                              title={i.course?.title||""}
                              pages={i.course?.duration||0}
                              subtopics={i.course?.lessons||0}
                              rating={i.course?.totalRating||0}
                              path={`/user/course/${i?.course?.courseCategoryId}/${i?.course?._id}`}
                            />
                          ))}
                        </div>
                      </div>
                      <div>
                        <p className="userSettingsDiv2Comp1text">
                          Your Skills
                        </p>
                        <div className="userHandWrittenNotesCards gridLayout4">
                          {purchasedSkills?.map((i, index) => (
                            <CapsuleUserCardComponentTranscript
                              name={i.skill?.name}
                              image={i.skill?.image||""}
                              title={i.title}
                              pages={i.skill?.duration||0}
                              subtopics={i.skill?.lessons||0}
                              rating={i.skill?.totalRating||0}
                              path={`/user/skills/${i?.skill?._id}`}
                              price={i.skill?.price}
                          
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="userSettingsDiv3Comp1">
                    <p>You haven't purchased any tests yet!</p>
                    <p>
                      Your transaction history of tests purchased on testbook
                      will be available here.
                    </p>
                    <Button>Purchase Course or test</Button>
                  </div>
                )}
              </div>
            )}
            {slectedSlide === 3 && (
              <div className="userSettingsDiv4">
                <p className="userSettingsDiv4Comp1P">All Dashboards</p>
                <div className="gridLayout2">
                  {allDashboard.map((i, index) => (
                    <div
                      key={index}
                      className="userSettingsDiv4Comp212"
                      onClick={() => navigate(i.path)}
                    >
                      <p>
                        <img
                          src={i.image}
                          alt=""
                          className="userSettingsDiv4Comp2Img"
                        />
                      </p>
                      <p className="userSettingsDiv4Comp2P">{i.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {slectedSlide === 4 && (
              <div className="userSettingsDiv5">
                <TimelineComponent timelineItems={timeLineData||[]} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(Settings);
